
import Vue from 'vue';
import { mapActions } from 'vuex';
import BaseIcon from './BaseIcon.vue';

export default Vue.extend({
  name: 'BaseToast',

  components: { BaseIcon },

  props: {
    notification: {
      type: Object as () => Store.Notification.Notification,
      required: true,
    },
  },

  computed: {
    toastIcon() {
      switch (this.notification.type) {
        case 'error': return 'mdi-alert-circle-outline';
        case 'success': return 'mdi-check';
        default: return null;
      }
    },
  },

  mounted() {
    setTimeout(() => this.remove(this.notification), 3500);
  },

  methods: mapActions('notification', ['remove']),
});
