type Hostname = 'gallery.jmvstream.com' | 'gallery-sandbox.jmvstream.com' | 'gallery-staging.jmvstream.com' | 'localhost';

const { hostname } = window.location as { hostname: Hostname };
let data;

switch (hostname) {
  case 'gallery.jmvstream.com':
    data = {
      API_URL: 'https://api-gallery.jmvstream.com/',
    };
    break;

  case 'gallery-sandbox.jmvstream.com':
    data = {
      API_URL: 'https://api-gallery-sandbox.jmvstream.com/',
    };
    break;

  case 'gallery-staging.jmvstream.com':
    data = {
      API_URL: 'https://api-gallery-staging.jmvstream.com/',
    };
    break;

  default:
    data = {
      API_URL: 'http://localhost:7010/',
    };
    break;
}

export const urls = data;
