
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseLoading',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
});
