import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { notification } from './modules/notification';
import { user } from './modules/user';
import { video } from './modules/video';

Vue.use(Vuex);

const storeOptions: StoreOptions<RootState> = {
  state: {
    version: '1.0.0',
  },
  modules: {
    notification,
    video,
    user,
  },
};

const store = new Vuex.Store<RootState>(storeOptions);

export { store };
