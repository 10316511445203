import { render, staticRenderFns } from "./BaseToast.vue?vue&type=template&id=2298073b&scoped=true&"
import script from "./BaseToast.vue?vue&type=script&lang=ts&"
export * from "./BaseToast.vue?vue&type=script&lang=ts&"
import style0 from "./BaseToast.vue?vue&type=style&index=0&id=2298073b&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2298073b",
  null
  
)

export default component.exports