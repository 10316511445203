
import Vue from 'vue';
import { mapState } from 'vuex';
import { BaseToast } from '@/components/BaseComponents';

export default Vue.extend({
  name: 'AppToastArea',
  components: { BaseToast },
  computed: mapState('notification', ['notifications']),
});
