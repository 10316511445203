
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseCard',

  inheritAttrs: false,

  props: {
    align: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: '#FFF',
    },
    backgroundImg: {
      type: String,
      default: null,
    },
    elevation: {
      type: Number,
      default: 2,
    },
    iframe: {
      type: String,
      default: null,
    },
    justify: {
      type: String,
      default: '',
    },
    noPad: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Number,
      default: 8,
    },
    row: {
      type: Boolean,
      default: false,
    },
    transparent: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: '',
    },
  },

  computed: {
    contentStyle(): string {
      return `
      flex-direction: ${this.row ? 'row' : 'column'};
      ${this.align && `align-items: ${this.align};`};
      ${this.justify && `justify-content: ${this.justify};`};
      `;
    },
    shadow(): string {
      if (this.transparent) return 'elevation type1';

      switch (this.elevation) {
        case 1:
          return 'elevation type1';
        case 2: default:
          return 'elevation type2';
        case 3:
          return 'elevation type3';
        case 4:
          return 'elevation type4';
      }
    },
    style(): string {
      return `
        ${this.transparent && 'background-color: transparent'};
        border-radius: ${this.radius}px;
      `;
    },
  },
});
