import { ActionTree } from 'vuex';

const actions: ActionTree<Store.User.State, RootState> = {

  fetchCustomerPlan({ commit }, getCustomerPlan: Services.User.Response.GetCustomerPlan) {
    commit('setCustomerPlan', getCustomerPlan);
  },

  fetchCustomerPlaId({ commit }, getCustomerPlanId: Services.User.Response.GetCustomerPlan) {
    commit('setCustomerPlan', getCustomerPlanId);
  },

  fetchCustomerPlansLive({ commit }, getCustomerPlansLive:
    Services.User.Response.GetCustomerPlansLive) {
    commit('setCustomerPlansLive', getCustomerPlansLive);
  },

  fetchAllCustomerPlans({ commit }, getAllCustomerPlans:
    Services.User.Response.GetAllCustomerPlans) {
    commit('setAllCustomerPlans', getAllCustomerPlans);
  },

  fetchCustomerPlansPlayerWebTV({ commit }, response:
    Services.User.Response.GetCustomerPlansPlayerWebTV) {
    commit('setCustomerPlansPlayerWebTV', response.data);
  },

  fetchHotsiteMediaSocial({ commit }, getHotsiteMediaSocial:
    Services.User.Response.HotsiteMediaSocial) {
    commit('setHotsiteMediaSocial', getHotsiteMediaSocial);
  },

  fetchAllHotsiteMediaSocial({ commit }, getAllHotsiteMediaSocial:
    Services.User.Response.AllHotsiteMediaSocial) {
    commit('setAllHotsiteMediaSocial', getAllHotsiteMediaSocial);
  },

  createHotsiteMediaSocial({ commit }, createdHotsiteMediaSocial:
    Services.User.Response.CreatedHotsiteMediaSocial) {
    commit('setCreateHotsiteMediaSocial', createdHotsiteMediaSocial);
  },

  createSelectedPlansId({ commit }, createdSelectedPlansId:
    Services.User.Response.CreatedHotsiteMediaSocial) {
    commit('setCreateSelectedPlansId', createdSelectedPlansId);
  },

  updateHotsiteMediaSocial({ commit }, updatedHotsiteMediaSocial:
    Services.User.Response.UpdateHotsiteMediaSocial) {
    commit('setUpdateHotsiteMediaSocial', updatedHotsiteMediaSocial);
  },
};

export { actions };
