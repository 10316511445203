import { format as dateFnsFormat } from 'date-fns';

class Helpers {
  static async copyToClipboard(value: string) {
    if ('clipboard' in navigator) {
      return navigator
        .clipboard
        .writeText(value)
        .catch((error) => {
          console.error(`[COPY ERROR]:\n${error}`);

          throw new Error('Error copying to clipboard');
        });
    }

    console.error('[CLIPBOARD ERROR]:\nClipboard is not supported in this browser.');

    throw new Error('Clipboard is not supported in this browser');
  }

  static formatDate(locale: 'en-US' | 'pt-BR', date: string) {
    let dateFormat: string;

    switch (locale) {
      case 'en-US': default: dateFormat = 'yyyy/MM/dd'; break;
      case 'pt-BR': dateFormat = 'dd/MM/yyyy'; break;
    }

    return dateFnsFormat(new Date(date), dateFormat);
  }
}

export { Helpers };
