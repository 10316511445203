import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vuetify.config.silent = true;

Vue.use(Vuetify);

const vuetify = new Vuetify({
  breakpoint: {
    thresholds: {
      xs: 360,
      sm: 601,
      md: 1201 + 16,
      lg: 1920 + 16,
    },
    mobileBreakpoint: 'sm',
  },
  customVariables: ['@/styles/_vuetify-overlap.sass'],
  treeShake: true,
  theme: {
    themes: {
      light: {
        dark: '#131336',
        primary: '#46467E',
        secondary: '#FFFFFF',
      },
    },
  },
});

export { vuetify };
