
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseIcon',

  props: {
    backColor: {
      type: String,
      default: null,
    },
    badge: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'accent',
    },
    filled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    noMask: {
      type: Boolean,
      default: false,
    },
    radius: {
      type: Number,
      default: null,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: 'normal',
    },
    stroked: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    iconPath: '',
  }),

  computed: {
    customIconStyle(): string {
      this.updateIconPath();

      return `
        background-color: ${this.isDefaultColor ? null : this.color};
        border-radius: ${this.rounded ? '50%' : `${this.radius}px`};
        height: ${this.iconSize}px;
        ${this.noMask ? `background: no-repeat center/contain url(${this.iconPath})` : `mask-image: url(${this.iconPath})`};
        width: ${this.iconSize}px;
      `;
    },
    iconParentStyle(): string {
      return `
        background-color: ${this.filled ? (this.backColor || this.$vuetify.theme.themes.light.secondary) : null};
        border: 2px solid ${this.stroked ? (this.backColor || this.$vuetify.theme.themes.light.secondary) : 'transparent'};
        border-radius: ${this.rounded ? '50%' : `${this.radius}px`};
        width: ${this.iconSize + 8}px;
        height: ${this.iconSize + 8}px;
      `;
    },
    iconSize(): number {
      if (typeof this.size === 'number') {
        return this.size;
      }

      switch (this.size) {
        case 'small':
          return 16;
        case 'normal': default:
          return 24;
        case 'big':
          return 36;
        case 'large':
          return 48;
        case 'x-large':
          return 58;
      }
    },
    iconStyle(): string {
      return `
        color: ${this.isDefaultColor ? null : this.color};
        font-size: ${this.isCustomIcon ? null : this.iconSize}px;
      `;
    },
    isCustomIcon(): boolean {
      return this.icon.slice(0, 3) !== 'mdi' && this.icon.slice(0, 3) !== 'fa-';
    },
    isDefaultColor(): boolean {
      return this.color === 'accent';
    },
  },

  methods: {
    async updateIconPath(): Promise<void> {
      await import(`@/assets/icons/${this.icon}.svg`)
        .then((response) => { this.iconPath = response.default; });
    },
  },
});
