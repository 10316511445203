import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';

const state: Store.Notification.State = {
  notifications: [],
};

export const notification: Module<Store.Notification.State, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
};
