
import Vue from 'vue';
import { gallery as galleryServices } from '@/services/gallery';
import { AppToastArea } from '@/components';

export default Vue.extend({
  name: 'App',

  components: { AppToastArea },

  data() {
    return {
      haveColorsBeenDefined: false,
    };
  },

  async mounted() {
    this.$root.$i18n.locale = navigator.language === 'pt-BR' ? 'pt-BR' : 'en-US';

    const colors = await this.getColors();

    if (colors === 'error') {
      this.haveColorsBeenDefined = true;

      return;
    }

    const {
      primary_color: primaryColor,
      secondary_color: secondaryColor,
    } = colors;

    this.setSiteColor('primary-color', primaryColor);
    this.setSiteColor('secondary-color', secondaryColor);

    this.haveColorsBeenDefined = true;
  },

  methods: {
    async getColors(): Promise<Services.Gallery.Response.GetColors | 'error'> {
      const encryptedGalleryId = window.location.pathname.split('/')[1];

      return galleryServices.getColors(encryptedGalleryId)
        .then(({ data }) => data)
        .catch((error) => {
          console.error('App - method: "getColors()" ', error);

          return 'error';
        });
    },
    setSiteColor(key: 'primary-color' | 'secondary-color', value: string) {
      document.documentElement.style.setProperty(`--${key}`, value);

      this.$vuetify.theme.themes.light[key.split('-').shift() as string] = value;
    },
  },
});
