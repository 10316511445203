const routes: Array<AppRouteConfig> = [
  {
    path: '/404',
    redirect: {
      name: 'Error',
      params: { error: '404' },
    },
  },
  {
    path: '/video',
    name: 'Video',
    component: () => import('../views/ViewVideo.vue'),
    meta: { backButton: true },
  },
  {
    path: '/:encryptedGalleryId/Gallery',
    name: 'Gallery',
    props: true,
    component: () => import('../views/ViewGallery.vue'),
  },
  {
    path: '/:encryptedGalleryId/channel',
    name: 'Channel',
    props: true,
    component: () => import('../views/ViewChannel.vue'),
    meta: { backButton: true },
  },
  {
    path: '/*',
    name: 'Error404',
    redirect: {
      name: 'Error',
      params: { error: '404' },
    },
  },
  {
    path: '/:error',
    name: 'Error',
    component: () => import('../views/ViewError.vue'),
    props: true,
  },
];

export { routes };
