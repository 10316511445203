
import Vue from 'vue';

export default Vue.extend({
  name: 'BaseMenu',

  inheritAttrs: false,

  props: {
    activatorWidth: {
      type: [Number, String],
      default: '100%',
    },
    closeOnContentClick: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    filter: {
      type: String,
      default: null,
    },
    fontColor: {
      type: String,
      default: 'white',
    },
    items: {
      type: Array,
      default: null,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    list: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: [Number, String],
      default: 240,
    },
    maxWidth: {
      type: [Number, String],
      default: 'min-content',
    },
    noPad: {
      type: Boolean,
      default: false,
    },
    side: {
      type: String,
      default: 'left',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      selectedItem: 0,
    };
  },

  computed: {
    activatorSlotStyle(): string {
      return `width: ${typeof this.activatorWidth === 'number' ? `${this.activatorWidth}px` : this.activatorWidth}`;
    },
    itemColor(): string {
      return `color: ${this.fontColor}`;
    },
    itemsWithObject(): Array<any> {
      return this.items.filter((item) => typeof item === 'object');
    },
    menuHeight(): string {
      return `max-height: ${typeof this.maxHeight === 'number' ? `${this.maxHeight}px` : this.maxHeight}`;
    },
    menuStyle(): string {
      return `
        background: ${this.color} !important;
        filter: ${this.filter};
      `;
    },
    triangleStyle(): string {
      return `
        border-bottom-color: ${this.color};
        filter: ${this.filter};
        ${this.side === 'left' ? 'right' : 'left'}: 8px;
      `;
    },
    valueIndex(): number {
      if (this.value === null) return 0;

      if (typeof this.value === 'number') {
        return (this.value > 0) && ((this.items.length - 1) >= this.value)
          ? this.value
          : 0;
      }

      const objectIndex = this.items.findIndex((item: any) => (
        item[this.itemText] === this.value || item[this.itemValue] === this.value
      ));

      if (objectIndex > -1) return objectIndex;

      const index = this.items.indexOf(this.value);
      return (index > 0) ? index : 0;
    },
  },

  watch: {
    selectedItem(): void {
      if (this.selectedItem !== undefined) {
        this.emitValue();
      }
    },
  },

  mounted() {
    if (this.list && this.items) {
      this.selectedItem = this.valueIndex;
      this.emitValue();
    }
  },

  methods: {
    emitValue(): void {
      const selectedItem: any = this.items[this.selectedItem];

      if (this.isItemAnObject(selectedItem)) {
        const text = selectedItem[this.itemText];
        const value = selectedItem[this.itemValue];

        this.$emit('input', this.selectedItem);
        this.$emit('change', value !== undefined ? value : text);
      } else {
        this.$emit('input', this.selectedItem);
        this.$emit('change', selectedItem);
      }
    },
    isItemAnObject(item: any): boolean {
      return this.itemsWithObject.indexOf(item) > -1;
    },
  },
});
