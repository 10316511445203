
import Vue from 'vue';
import { Helpers } from '@/utils';

export default Vue.extend({
  name: 'BaseInput',

  props: {
    appendIcon: {
      type: String,
      default: '',
    },
    appendIconDisabled: {
      type: Boolean,
      default: false,
    },
    appendOuterIcon: {
      type: String,
      default: '',
    },
    backgroundColor: {
      type: String,
      default: null,
    },
    copy: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 53,
    },
    label: {
      type: String,
      default: '',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    pill: {
      type: Boolean,
      default: false,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      copySuccess: false,
    };
  },

  computed: {
    handleAppendIcon() {
      return this.copy ? 'mdi-content-copy' : this.appendIcon;
    },
  },

  methods: {
    handleClickAppend(event: Event) {
      if (this.copy) this.handleCopy();

      this.$emit('clickAppend', event);
    },
    handleCopy() {
      Helpers
        .copyToClipboard(this.value)
        .then(() => this.$emit('copied', this.value))
        .then(() => {
          this.copySuccess = true;

          setTimeout(() => { this.copySuccess = false; }, 1500);
        })
        .catch(() => { /**/ });
    },
  },
});
