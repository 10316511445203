import { ActionTree } from 'vuex';

const actions: ActionTree<Store.Notification.State, RootState> = {
  create({ commit }, notification: Store.Notification.Notification) {
    commit('create', notification);
  },
  remove({ commit }, notification: Store.Notification.Notification) {
    commit('remove', notification);
  },
};

export { actions };
