import { MutationTree } from 'vuex';

const mutations: MutationTree<Store.User.State> = {
  setCustomerPlan(state, customerPlan) {
    state.customerPlan = customerPlan;
  },

  setCustomerPlanId(state, customerPlanId) {
    state.customerPlanId = customerPlanId;
  },

  setCustomerPlansLive(state, customerPlansLive) {
    state.customerPlansLive = customerPlansLive;
  },

  setAllCustomerPlans(state, allCustomerPlans) {
    state.allCustomerPlans = allCustomerPlans;
  },

  setCustomerPlansPlayerWebTV(state, customerPlansPlayerWebTV) {
    state.customerPlansPlayerWebTV = customerPlansPlayerWebTV;
  },

  setHotsiteMediaSocial(state, hotsiteMediaSocial) {
    state.hotsiteMediaSocial = hotsiteMediaSocial;
  },

  setAllHotsiteMediaSocial(state, allHotsiteMediaSocial) {
    state.allHotsiteMediaSocial = allHotsiteMediaSocial;
  },

  addHotsiteMediaSocial(state, createdHotsiteMediaSocial) {
    state.createdHotsiteMediaSocial = createdHotsiteMediaSocial;
  },

  addHSelectedPlansId(state, createdSelectedPlansId) {
    state.createdSelectedPlansId = createdSelectedPlansId;
  },

  updateHotsiteMediaSocial(state, updatedHotsiteMediaSocial) {
    state.updatedHotsiteMediaSocial = updatedHotsiteMediaSocial;
  },

};

export { mutations };
