import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';

export const user: Module<Store.User.State, RootState> = {
  actions,
  mutations,
  namespaced: true,
  state: {
  },
};
