var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"app-input-container"},[_c('div',{staticClass:"py-0 px-0"},[(_vm.label)?_c('label',[_vm._v(_vm._s(_vm.$t(_vm.label)))]):_vm._e(),_c('v-text-field',_vm._b({staticClass:"app-input",class:{
        'app-input--pill': _vm.pill,
        'app-input--outline': _vm.outline,
        'show-error': _vm.showError
      },attrs:{"value":_vm.value,"aria-label":"Input element","append-outer-icon":_vm.appendOuterIcon,"background-color":_vm.backgroundColor,"disabled":_vm.disabled,"height":_vm.height,"rounded":""},on:{"input":e => _vm.$emit('input', e),"click":e => _vm.$emit('click', e),"click:append-outer":e => _vm.$emit('clickAppendOuter', e)},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.handleAppendIcon)?_c('v-btn',{class:{
            'app-input--copy': _vm.copySuccess,
          },attrs:{"aria-label":`Appended icon: ${_vm.handleAppendIcon}`,"icon":"","disabled":_vm.appendIconDisabled},on:{"click":(e) => _vm.handleClickAppend(e)}},[_c('v-icon',[_vm._v(_vm._s(_vm.handleAppendIcon))])],1):_vm._e()]},proxy:true}])},'v-text-field',_vm.$attrs,false))],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }