import { api } from './api';

const getCategories = async (encryptedGalleryId: string) => {
  const encodedGalleryId = encodeURIComponent(encryptedGalleryId);

  return api.get<Services.Gallery.Response.GetCategories>(
    `categories/hotsites/${encodedGalleryId}`,
  );
};

const getColors = async (encryptedGalleryId: string) => (
  api.get<Services.Gallery.Response.GetColors>(
    `colors/hotsites/${encryptedGalleryId}`,
  )
);

const getVideos = async (payload: Services.Gallery.GetVideos) => (
  api.get<Services.Gallery.Response.GetVideos>(
    `videos/hotsites/${encodeURIComponent(payload.encryptedGalleryId)}`,
    { params: { ...payload, encryptedGalleryId: undefined } },
  )
);

export const gallery = {
  getCategories,
  getColors,
  getVideos,
};
