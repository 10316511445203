
import Vue from 'vue';

export default Vue.extend({
  name: 'BasePagination',

  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: 'primary',
    },
    fontColor: {
      type: String,
      default: null,
    },
    length: {
      type: Number,
      required: true,
    },
    square: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      selectedPage: this.value > 0 && this.value <= this.length
        ? this.value
        : 1,
    };
  },

  watch: {
    selectedPage(): void {
      this.emitPage();
    },
    value(): void {
      this.selectedPage = this.value;
    },
  },

  mounted() {
    this.emitPage();
    this.removeHoverFromSelectedItem();

    if (this.color !== 'primary') this.changeColor();
  },

  updated() {
    this.removeHoverFromSelectedItem();

    if (this.color !== 'primary') this.changeColor();
  },

  methods: {
    changeColor(): void {
      const items: NodeListOf<HTMLButtonElement> = this.$el.querySelectorAll('.v-pagination__item');

      items.forEach((item) => {
        if (item.innerHTML !== this.selectedPage.toString()) {
          Object.assign(item.style, { color: this.color });
        } else if (this.fontColor) {
          item.style.setProperty('color', this.fontColor, 'important');
        }
      });
    },
    emitPage(): void {
      this.$emit('input', this.selectedPage);
    },
    removeHoverFromSelectedItem(): void {
      const itemActive = this.$el.querySelector('.v-pagination__item--active') as HTMLButtonElement;
      if (itemActive) itemActive.classList.add('no-hover');
    },
  },
});
