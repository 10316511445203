import { MutationTree } from 'vuex';

let nextId = 0;

const mutations: MutationTree<Store.Notification.State> = {
  create(state, payload: Store.Notification.Notification) {
    state.notifications.push({
      ...payload,
      id: nextId += 1,
    });
  },
  remove(state, notification: Store.Notification.Notification) {
    state.notifications = state.notifications.filter((
      toast: Store.Notification.Notification,
    ) => toast.id !== notification.id);
  },
};

export { mutations };
